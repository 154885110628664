import http from "@src/api/http";
import { AuthContext, TUser } from "@src/components/auth/auth.ctx";
import { API_BASE_URL } from "@src/utils/env.values";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useContext } from "react";

const organizationBaseUrl = `${API_BASE_URL}/organization`;


type Organization = {
  id: string
  name: string
  schema: string
}

type OrganizationResponse = {
  organizations: Organization[]
  message: string
}

const getOrganizationsQueryKey = (user: TUser | null) => ["organizations", user?.user_id]

export const useGetOrganizations = () => {
  const { user } = useContext(AuthContext)
  return useQuery<OrganizationResponse, Error>({
    queryKey: getOrganizationsQueryKey(user),
    queryFn: () => http.get(organizationBaseUrl),
    enabled: !!user && user.permissions.list_organizations
  })
}
