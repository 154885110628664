import logo from '@assets/revamp-logo.png';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import FilterListIcon from '@mui/icons-material/FilterList';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import PersonSearchOutlinedIcon from '@mui/icons-material/PersonSearchOutlined';
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined';
import { IconButton, Stack, Tooltip, styled, useTheme } from "@mui/material";
import UserSettings from '@src/components/UserSettings';
import { campaigns, companies, leads, pipeline, rootPath, unibox } from "@src/utils/spa.url";
import { Sidebar } from "react-pro-sidebar";
import { Link, useLocation } from "react-router-dom";

const StyledLink = styled(Link, { shouldForwardProp: (prop) => prop !== 'isActive' })<{ isActive: boolean }>(({ theme, isActive }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  textDecoration: 'none',
  opacity: !isActive ? 0.5 : 1,
  '&:hover': {
    opacity: 1,
  },
  transition: theme.transitions.create(['opacity'], {
    duration: theme.transitions.duration.shortest,
    easing: theme.transitions.easing.easeInOut,
  }),
}))

function SidebarItem({ icon, label, path }: { icon: React.ReactNode, label: string, path: string }) {
  const theme = useTheme();
  const { pathname } = useLocation();

  const isActive = pathname.startsWith(path);
  const color = isActive ? theme.palette.secondary.main : theme.palette.background.paper;

  return (
    <Tooltip title={label} placement='right'>
      <StyledLink to={path} isActive={isActive}>
        <IconButton sx={{ color }}>
          {icon}
        </IconButton>
      </StyledLink>
    </Tooltip>
  );
}


const sidebarWidth = '50px';

export default function AppSidebar() {
  const theme = useTheme();

  return (
    <Sidebar width={sidebarWidth} backgroundColor={theme.palette.dark.main}
      style={{
        border: 'none',
        height: '100%',
      }}
    >
      <Stack paddingY={2} height='100%'>
        {/* Logo Section */}
        <Link to={rootPath} style={{ height: 'auto', display: 'flex', justifyContent: 'center' }}>
          <img src={logo} alt="Revamp logo" style={{ width: '55%', height: 'auto' }} />
        </Link>

        {/* Main Menu Section */}
        <Stack gap={3} flex={1} marginTop={theme.spacing(6)}>
          <SidebarItem icon={<FilterListIcon />} label="Pipeline" path={pipeline.base} />
          <SidebarItem icon={<PersonSearchOutlinedIcon />} label="Leads" path={leads.base} />
          <SidebarItem icon={<CorporateFareOutlinedIcon />} label="Companies" path={companies.base} />
          <SidebarItem icon={<CampaignOutlinedIcon />} label="Campaigns" path={campaigns.base} />
          <SidebarItem icon={<MailOutlinedIcon />} label="Unibox" path={unibox.base} />

          {/* INFO: Uncomment this when we want to expose this page later on */}
          {/* <SidebarItem icon={<ContactsOutlinedIcon />} label="Contacts" path={contacts.base} /> */}
        </Stack>

        {/* Other Section */}
        <Stack>
          <UserSettings />
        </Stack>
      </Stack>
    </Sidebar>
  );
}
