import { AccountBoxOutlined, CableOutlined, ChevronRightOutlined, HelpCenterOutlined, Logout } from '@mui/icons-material';
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined';
import { Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { useChangeUserOrganization } from '@src/api/auth/hook';
import { useGetOrganizations } from '@src/api/organization/hook';
import { AppAlertsCtx } from '@src/components/AppAlerts';
import ComponentSpinner from '@src/components/ComponentSpinner';
import UserAvatar from '@src/components/UserAvatar';
import { AuthContext } from "@src/components/auth/auth.ctx";
import { useHubSpotChat } from '@src/components/hubspot/useHubSpotChat';
import { auth, integrations, rootPath } from "@src/utils/spa.url";
import { NestedMenuItem } from 'mui-nested-menu';
import { useContext, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";

export default function UserSettings() {
  const navigate = useNavigate()
  const { addAlert } = useContext(AppAlertsCtx);
  const { isAuthenticated, user, setUser } = useContext(AuthContext);
  const [mainAnchorEl, setMainAnchorEl] = useState<null | HTMLElement>(null);
  const isMainMenuOpen = Boolean(mainAnchorEl);

  const { isReady, openHandler } = useHubSpotChat()

  const { data: organizations, isLoading: isLoadingOrganizations } = useGetOrganizations()
  const { mutateAsync: changeOrganization, isPending: isChangingOrganization } = useChangeUserOrganization({
    onSuccess: () => {
      navigate(rootPath)
    }
  })

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setMainAnchorEl(event.currentTarget);
  };

  const handleMainMenuClose = () => {
    setMainAnchorEl(null);
  };

  const handleOrganizationChange = async (orgId: string, orgName: string) => {
    if (orgId === user?.organization_id || !user) {
      return;
    }
    const canChangeOrganization = user.permissions.change_organization
    if (!canChangeOrganization) {
      addAlert({
        message: 'You do not have permission to change organizations',
        type: 'error'
      })
      return;
    }
    await changeOrganization({ user_id: user.user_id, organization_id: orgId })
    setUser({ ...user, organization_id: orgId, orgName: orgName })
    handleMainMenuClose()
  }

  if (!isAuthenticated || !user) {
    return null;
  }

  const canListOrganizations = user.permissions.list_organizations

  return (
    <>
      <IconButton onClick={handleClick} size='large'>
        <UserAvatar name={user.first_name} />
      </IconButton>
      <Menu
        anchorEl={mainAnchorEl}
        open={isMainMenuOpen}
        onClose={handleMainMenuClose}
        MenuListProps={{ dense: true, sx: { minWidth: 200 } }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <NestedMenuItem
          parentMenuOpen={isMainMenuOpen}
          leftIcon={(
            <ListItemIcon sx={{ marginLeft: 1.5 }}>
              <AccountBoxOutlined fontSize="small" />
            </ListItemIcon>
          )}
          rightIcon={canListOrganizations && (
            <ListItemIcon>
              <ChevronRightOutlined fontSize="small" />
            </ListItemIcon>
          )}
          sx={{ opacity: '1 !important' }}
          disabled={isChangingOrganization || isLoadingOrganizations || !canListOrganizations}
          MenuProps={{
            MenuListProps: { dense: true },
            anchorOrigin: {
              vertical: 'center',
              horizontal: 'right',
            },
            transformOrigin: {
              vertical: 'center',
              horizontal: 'left',
            },
          }}
          renderLabel={() => (
            <ListItemText primary={`${user.first_name} ${user.last_name}`} secondary={user.orgName} />
          )}>

          {organizations?.organizations?.map((org) => (
            <MenuItem key={org.id} onClick={() => handleOrganizationChange(org.id, org.name)} selected={user.organization_id === org.id} disabled={isChangingOrganization}>
              <ListItemIcon>
                {(isChangingOrganization && user.organization_id !== org.id) ? <ComponentSpinner size={16} /> : <CorporateFareOutlinedIcon fontSize="small" />}
              </ListItemIcon>
              <ListItemText primary={org.name} />
            </MenuItem>
          ))}

        </NestedMenuItem>

        <Divider />

        <MenuItem component={Link} to={integrations.base} onClick={() => handleMainMenuClose()}>
          <ListItemIcon>
            <CableOutlined fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            Settings
          </ListItemText>
        </MenuItem>

        <MenuItem onClick={() => { openHandler(); handleMainMenuClose() }} disabled={!isReady}>
          <ListItemIcon>
            <HelpCenterOutlined fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            Support
          </ListItemText>
        </MenuItem>

        <Divider sx={{ margin: `0 !important` }} />
        <MenuItem component={Link} to={auth.logout} onClick={handleMainMenuClose}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            Logout
          </ListItemText>
        </MenuItem>
      </Menu>
    </>
  )
}
