import { MessageError } from "@src/api/chat/hook";
import { API_BASE_URL } from "@src/utils/env.values";
import http from "@src/api/http";
import { UseMutationOptions, UseQueryOptions, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

type TOrganization = {
  organization_id: string;
  organization_name: string;
}

export type TPermissions = {
  add_leads: boolean;
  change_organization: boolean;
  list_organizations: boolean;
}

type TCommonAuthUserResponse = {
  user_id: string;
  email: string;
  organization: TOrganization;
  enable_production: boolean;
  created_at: string,
  first_name: string,
  last_name: string,
  permissions: TPermissions
}

const userBaseURL = `${API_BASE_URL}/users`;

type TLoginArgs = {
  email: string;
  password: string;
}

type TLoginResponse = TCommonAuthUserResponse & {
  valid: boolean;
}

export function useLogin(options?: UseMutationOptions<TLoginResponse, Error, TLoginArgs>) {
  return useMutation<TLoginResponse, Error, TLoginArgs>({
    mutationFn: (creds: TLoginArgs) => http.post(`${userBaseURL}/login`, creds),
    ...options
  })
}


export function useLogout(options?: Omit<UseQueryOptions, "queryKey">) {
  return useQuery({
    queryKey: ["logout"],
    queryFn: () => http.get(`${userBaseURL}/logout`),
    ...options
  })
}

type TUserDetailsResponse = TCommonAuthUserResponse;

export const getUserDetailsQueryKey = () => ["userDetails"];

export function useUserDetails(options?: UseQueryOptions<TUserDetailsResponse>) {

  return useQuery<TUserDetailsResponse>({
    queryKey: getUserDetailsQueryKey(),
    queryFn: () => http.get(`${userBaseURL}/me`),
    retry: 0,
    ...options
  })
}

type ChangeOrganizationArgs = {
  user_id: string
  organization_id: string
}

type ChangeOrganizationResponse = {
  message: string
}

export const useChangeUserOrganization = (options?: UseMutationOptions<ChangeOrganizationResponse, Error, ChangeOrganizationArgs>) => {
  const queryClient = useQueryClient()
  return useMutation<ChangeOrganizationResponse, Error, ChangeOrganizationArgs>({
    mutationFn: (args: ChangeOrganizationArgs) => http.put(`${userBaseURL}/${args.user_id}/organization`, args),
    onSuccess: async (data, variables, context) => {
      queryClient.invalidateQueries()
      options?.onSuccess?.(data, variables, context)
    }
  })
}



// Signup Hook
const signupURL = `${API_BASE_URL}/signup`;

type TSignupArgs = {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
}

type TSignupResponse = {
  valid: boolean;
  user: string;
}

export function useSignup(options?: UseMutationOptions<TSignupResponse, Error, TSignupArgs>) {
  return useMutation<TSignupResponse, Error, TSignupArgs>({
    mutationFn: (data: TSignupArgs) => http.post(signupURL, data),
    ...options
  })
}

const confirmEmailURL = `${API_BASE_URL}/confirm-email`;

type TConfirmEmailArgs = {
  token: string;
}

type TConfirmEmailResponse = {
  type: 'success' | 'error';
  message: string;
}

export function useConfirmEmail(options?: UseMutationOptions<TConfirmEmailResponse, MessageError, TConfirmEmailArgs>) {
  return useMutation<TConfirmEmailResponse, MessageError, TConfirmEmailArgs>({
    mutationFn: (data: TConfirmEmailArgs) => http.post(confirmEmailURL, data),
    ...options
  })
}


const resetPasswordURL = `${API_BASE_URL}/reset_password`;

type TResetPasswordArgs = {
  email: string;
}

type TResetPasswordResponse = {
  message: string;
}

export function useResetPassword(options?: UseMutationOptions<TResetPasswordResponse, Omit<MessageError, 'type'>, TResetPasswordArgs>) {
  return useMutation<TResetPasswordResponse, Omit<MessageError, 'type'>, TResetPasswordArgs>({
    mutationFn: (data: TResetPasswordArgs) => http.post(resetPasswordURL, data),
    ...options
  })
}

const changePasswordURL = `${API_BASE_URL}/change_password`;

type TChangePasswordArgs = {
  token: string;
  new_password: string;
}

type TChangePasswordResponse = {
  message: string;
}

export function useChangePassword(options?: UseMutationOptions<TChangePasswordResponse, Omit<MessageError, 'type'>, TChangePasswordArgs>) {
  return useMutation<TChangePasswordResponse, Omit<MessageError, 'type'>, TChangePasswordArgs>({
    mutationFn: (data: TChangePasswordArgs) => http.post(changePasswordURL, data),
    ...options
  })
}
