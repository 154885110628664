import { Button, ButtonProps, CircularProgress } from "@mui/material";
import { forwardRef } from "react";
import { LinkProps } from "react-router-dom";


type Props = ButtonProps & {
  isLoading: boolean;
  to?: string;
  state?: LinkProps['state']
};

type Sizes = ButtonProps['size'];

const SizeMap: { [key in Sizes as string]: number } = {
  small: 18,
  medium: 22,
  large: 26,
  xl: 26,
};

const LoadingButton = forwardRef<HTMLButtonElement, Props>(({ isLoading, ...props }, ref) => {
  const disabled = isLoading || props.disabled;
  return (
    <Button {...props} ref={ref} disabled={disabled}>
      {isLoading && <CircularProgress size={SizeMap[props.size || 'medium']} color='inherit' sx={{ marginRight: 1 }} />}
      {props.children}
    </Button>
  );
});

LoadingButton.displayName = "LoadingButton";

export default LoadingButton;
