import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Grid, IconButton, TextField, Typography } from "@mui/material";
import LoadingButton from '@src/components/LoadingButton';
import { useEffect, useState } from 'react';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import sqlLang from 'react-syntax-highlighter/dist/esm/languages/hljs/sql';

SyntaxHighlighter.registerLanguage('sql', sqlLang);

type Props = DialogProps & {
  onEmailSubmit: (email: string) => void
  isLoading: boolean
}

export function ForgotPasswordDialog({ onEmailSubmit, isLoading, ...props }: Props) {
  const [email, setEmail] = useState('');

  useEffect(() => {
    setEmail('');
  }, [props.open]);

  return (
    <Dialog {...props} maxWidth="xs" fullWidth>
      <form onSubmit={(e) => { e.preventDefault(); onEmailSubmit(email) }}>
        <DialogTitle>
          Reset your password
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => props.onClose?.({}, 'escapeKeyDown')}
          sx={{
            position: 'absolute',
            right: 10,
            top: 10,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body2">
                Hi there! Please submit your registered email address and we'll send you an email with your password reset link!
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="E-mail"
                type="email"
                fullWidth
                value={email}
                required
                InputLabelProps={{ required: false }}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="btnAction" variant="outlined" onClick={() => props.onClose?.({}, 'escapeKeyDown')}>
            Cancel
          </Button>
          <LoadingButton isLoading={isLoading} type="submit" color="btnAction" variant="contained" disabled={!email}>
            Submit
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
