import { Root } from '@pages/Root';
import { SuspenseWithFallback } from '@src/components/SuspenseWithFallback';
import PrivateRoute from '@src/components/auth/PrivateRoute';
import { AppLayout } from '@src/layout/AppLayout';
import { CenteredLayout } from '@src/layout/CenteredLayout';
import { Login } from '@src/pages/auth/Login';
import { auth, campaigns, companies, error, integrations, leads, pipeline, rootPath, unibox, unsubscribe } from '@utils/spa.url';
import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

const CampaignAnalytics = lazy(() => import('@src/pages/campaigns/CampaignAnalytics'));
const CampaignDetails = lazy(() => import('@src/pages/campaigns/CampaignDetails'));
const CampaignLeads = lazy(() => import('@src/pages/campaigns/CampaignLeads'));
const Campaigns = lazy(() => import('@src/pages/campaigns/Campaigns'));
const CampaignSequence = lazy(() => import('@src/pages/campaigns/CampaignSequence'));
const CampaignSetting = lazy(() => import('@src/pages/campaigns/CampaignSetting'));
const ChatPage = lazy(() => import('@src/pages/chat/ChatPage'));
const Company = lazy(() => import('@src/pages/company/Company'));
const CompanyDetails = lazy(() => import('@src/pages/company/CompanyDetails'));
const ConfirmEmail = lazy(() => import('@src/pages/auth/ConfirmEmail'));
const Error = lazy(() => import('@src/pages/error/Error'));
const HubspotCallback = lazy(() => import('@src/pages/integrations/components/HubspotCallback'));
const Integrations = lazy(() => import('@src/pages/integrations/Integrations'));
const Leads = lazy(() => import('@src/pages/leads/Leads'));
const LeadsDetails = lazy(() => import('@src/pages/leads/LeadsDetails'));
const LeadSequence = lazy(() => import('@src/pages/leads/LeadSequence'));
const Logout = lazy(() => import('@src/pages/auth/Logout'));
const PageNotFound = lazy(() => import('@src/pages/Page404/PageNotFound'));
const PendingVerification = lazy(() => import('@src/pages/auth/PendingVerification'));
const ResetPassword = lazy(() => import('@src/pages/auth/ResetPassword'));
const SalesforceCallback = lazy(() => import('@src/pages/integrations/components/SalesforceCallback'));
const Signup = lazy(() => import('@src/pages/auth/Signup'));
const TrainingPage = lazy(() => import('@src/pages/training/TrainingPage'));
const Unibox = lazy(() => import('@src/pages/unibox/Unibox'));
const Unsubscribe = lazy(() => import('@src/pages/unsubscribe/Unsubscribe'));

export default function Router() {
  return (
    <Routes>
      {/* Auth */}
      <Route path={auth.signIn} element={<SuspenseWithFallback><Login /></SuspenseWithFallback>} />
      <Route path={auth.confirmEmail} element={<SuspenseWithFallback><ConfirmEmail /></SuspenseWithFallback>} />
      <Route path={auth.signup} element={<SuspenseWithFallback><Signup /></SuspenseWithFallback>} />
      <Route path={auth.pendingVerification} element={<SuspenseWithFallback><PendingVerification /></SuspenseWithFallback>} />
      <Route path={auth.resetPassword} element={<SuspenseWithFallback><ResetPassword /></SuspenseWithFallback>} />

      {/* Public Pages */}
      <Route path={error.base} element={<CenteredLayout />} >
        <Route index element={<SuspenseWithFallback><Error /></SuspenseWithFallback>} />
      </Route>
      <Route path={unsubscribe.base} element={<CenteredLayout />} >
        <Route index element={<SuspenseWithFallback><Unsubscribe /></SuspenseWithFallback>} />
      </Route>
      <Route path={integrations.base} element={<CenteredLayout />} >
        <Route path={integrations.salesforceCallback} element={<PrivateRoute outlet={<SuspenseWithFallback><SalesforceCallback /></SuspenseWithFallback>} />} />
        <Route path={integrations.hubspotCallback} element={<PrivateRoute outlet={<SuspenseWithFallback><HubspotCallback /></SuspenseWithFallback>} />} />
      </Route>

      {/* Core App Layout */}
      <Route element={<AppLayout />}>
        <Route path={rootPath} element={<SuspenseWithFallback><Root /></SuspenseWithFallback>} />
        <Route path={auth.logout} element={<PrivateRoute outlet={<SuspenseWithFallback><Logout /></SuspenseWithFallback>} />} />

        {/* Chat Copilot */}
        <Route path={pipeline.base} >
          <Route index element={<PrivateRoute outlet={<SuspenseWithFallback><ChatPage /></SuspenseWithFallback>} />} />
          <Route path={pipeline.training} element={<PrivateRoute outlet={<SuspenseWithFallback><TrainingPage /></SuspenseWithFallback>} />} />
        </Route>


        {/* Leads */}
        <Route path={leads.base}>
          <Route index element={<PrivateRoute outlet={<SuspenseWithFallback><Leads /></SuspenseWithFallback>} />} />
          <Route path={`:${leads.detailsKey}`} element={<PrivateRoute outlet={<SuspenseWithFallback><LeadsDetails /></SuspenseWithFallback>} />} />
          <Route path={`:${leads.detailsKey}${leads.leadSequencePath}`} element={<PrivateRoute outlet={<SuspenseWithFallback><LeadSequence /></SuspenseWithFallback>} />} />
        </Route>

        {/* Companies */}
        <Route path={companies.base}>
          <Route index element={<PrivateRoute outlet={<SuspenseWithFallback><Company /></SuspenseWithFallback>} />} />
          <Route path={`:${companies.detailsKey}`} element={<PrivateRoute outlet={<SuspenseWithFallback><CompanyDetails /></SuspenseWithFallback>} />} />
        </Route>

        {/* Campaigns */}
        <Route path={campaigns.base}>
          <Route index element={<PrivateRoute outlet={<SuspenseWithFallback><Campaigns /></SuspenseWithFallback>} />} />
          <Route path={`:${campaigns.detailsKey}`} element={<PrivateRoute outlet={<SuspenseWithFallback><CampaignDetails /></SuspenseWithFallback>} />} >
            <Route path={campaigns.sequenceBase} element={<PrivateRoute outlet={<SuspenseWithFallback><CampaignSequence /></SuspenseWithFallback>} />} />
            <Route path={campaigns.analyticsBase} element={<PrivateRoute outlet={<SuspenseWithFallback><CampaignAnalytics /></SuspenseWithFallback>} />} />
            <Route path={campaigns.leadsBase} element={<PrivateRoute outlet={<SuspenseWithFallback><CampaignLeads /></SuspenseWithFallback>} />} />
            <Route path={campaigns.settingsBase} element={<PrivateRoute outlet={<SuspenseWithFallback><CampaignSetting /></SuspenseWithFallback>} />} />
          </Route>
        </Route>

        {/* Unibox */}
        <Route path={unibox.base} element={<PrivateRoute outlet={<SuspenseWithFallback><Unibox /></SuspenseWithFallback>} />} />

        {/* Integrations */}
        <Route path={integrations.base}>
          <Route index element={<PrivateRoute outlet={<SuspenseWithFallback><Integrations /></SuspenseWithFallback>} />} />
        </Route>

      </Route>

      <Route element={<CenteredLayout />}>
        <Route path='*' element={<SuspenseWithFallback><PageNotFound /></SuspenseWithFallback>} />
      </Route>
    </Routes>
  );
}
