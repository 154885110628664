import { useUserDetails } from '@src/api/auth/hook';
import PageSpinner from '@src/components/PageSpinner';
import { AuthContextProvider, TAuthContext, TUser } from '@src/components/auth/auth.ctx';
import { auth, error, integrations, unsubscribe } from '@src/utils/spa.url';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

const ignoredPaths = [
  auth.signup,
  auth.pendingVerification,
  auth.confirmEmail,
  auth.resetPassword,
  integrations.salesforceCallback,
  integrations.hubspotCallback,
  unsubscribe.base,
  error.base
];

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [user, setUser] = useState<TUser | null>(null);
  const { data, isLoading, isError } = useUserDetails();
  const navigate = useNavigate();
  const { pathname, state, search } = useLocation();

  const authCtx: TAuthContext = useMemo(() => ({
    isAuthenticated,
    setAuthenticated,
    user,
    setUser,
    isLoading
  }), [isAuthenticated, isLoading, user]);

  useEffect(() => {
    if (!isLoading && isAuthenticated && user && data) {
      if (data.enable_production !== user.enable_production) {
        setUser({
          email: data.email, orgName: data.organization.organization_name,
          avatar: '',
          created_at: data.created_at,
          user_id: data.user_id,
          first_name: data.first_name,
          last_name: data.last_name,
          organization_id: data.organization.organization_id,
          enable_production: data.enable_production,
          permissions: data.permissions
        });
      }
    }
  }, [data, isAuthenticated, isLoading, user])

  useEffect(() => {
    // TODO: we should be able to remove this from here and add the logic to the PrivateRoute. Need to verify that the logic works
    if (!isLoading && !isAuthenticated) {

      if (isError && !data) {
        setAuthenticated(false);
        setUser(null);
        if (ignoredPaths.some(path => pathname.includes(path))) {
          return;
        }
        else {
          navigate(auth.signIn);
        }
        return;
      }
      if (!isError && data) {
        setAuthenticated(true);
        setUser({
          email: data.email, orgName: data.organization.organization_name,
          avatar: '',
          created_at: data.created_at,
          user_id: data.user_id,
          first_name: data.first_name,
          last_name: data.last_name,
          organization_id: data.organization.organization_id,
          enable_production: data.enable_production,
          permissions: data.permissions
        });
        navigate(`${pathname}${search}`, { replace: true });
        return
      }
    }
  }, [data, isAuthenticated, isError, isLoading, navigate, pathname, search, state])

  if (isLoading) {
    return <PageSpinner />;
  }


  return (
    <AuthContextProvider value={authCtx}>
      {children}
    </AuthContextProvider>
  );
}
