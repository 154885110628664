import React, { ErrorInfo } from 'react';
import { Grid } from '@mui/material';
import ErrorEmptyState from '@src/components/ErrorEmptyState';
import { lightTheme } from '@src/theme';
import * as Sentry from '@sentry/react';


interface IProps {
  children?: React.ReactNode;
}

interface IState {
  hasError: boolean;
}

class OldErrorBoundary extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { hasError: false };
  }

  public static getDerivedStateFromError(error: Error): IState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Logger.error(ErrorCodes.common.uncaughtExpection, `${error}, ${errorInfo.componentStack}`);
    console.error(error, errorInfo);
  }

  render() {
    const onClick = () => {
      this.setState({ hasError: false });
    };

    if (this.state.hasError) {
      return (
        <Grid container height='100vh' position='relative' sx={{ backgroundColor: lightTheme.palette.background.default }}>
          <Grid item flex={1} p={4} height='100vh' overflow='auto'>
            <ErrorEmptyState handleOnClick={onClick} />
          </Grid>
        </Grid>
      );
    }
    else {
      return this.props.children;
    }
  }
}

export default function ErrorBoundary({ children }: IProps) {
  return (
    <Sentry.ErrorBoundary
      showDialog
      fallback={(
        <Grid container height='100vh' position='relative' sx={{ backgroundColor: lightTheme.palette.background.default }}>
          <Grid item flex={1} p={4} height='100vh' overflow='auto'>
            <ErrorEmptyState handleOnClick={() => { }} />
          </Grid>
        </Grid>
      )}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
}

