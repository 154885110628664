import logo from '@assets/revamp-logo-full.png';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { Grid, IconButton } from "@mui/material";
import { websiteUrl } from '@src/utils/constants';
import { auth, rootPath } from '@src/utils/spa.url';
import Image from "mui-image";
import { Link, useLocation } from 'react-router-dom';

export const Header = () => {
  const { pathname } = useLocation();
  return (
    <Grid container padding={2} justifyContent="space-between" alignItems="center" sx={{ position: 'fixed', top: 0, left: 0, zIndex: 1 }}>
      <Grid item>
        {pathname !== auth.signIn && <Link to={rootPath}><Image src={logo} alt="Revamp Logo" width={80} /></Link>}
      </Grid>
      <Grid item>
        <IconButton component={Link} to={websiteUrl}>
          <HomeOutlinedIcon />
        </IconButton>
      </Grid>
    </Grid>
  )
}

